import React from 'react';

import Link from 'utils/localized-link';

import AboutFeatured from 'components/sections/about-featured';
import AboutHeader from 'components/sections/about-header';
import Awards from 'components/sections/awards';
import AboutMore from 'components/sections/about-more';

// Hidden for now:
// import AboutMedia from 'components/sections/about-media';
// import AboutMediaKit from 'components/sections/about-media-kit';
import Banner from 'components/sections/banner';
import Benefits from 'components/sections/benefits';
import Layout from 'components/layout';
import SEO from 'components/seo';

import ObjectiveIcon from 'images/icons/objective.inline.svg';
import EyeIcon from 'images/icons/eye.inline.svg';
import InfrastructureIcon from 'images/icons/infrastructure.inline.svg';

const bannerImage = 'home/robot-banner-bottom2.jpg';

const bannerButtons = [
  {
    linkComponent: Link,
    linkProps: {
      to: '/contacts'
    },
    light: true,
    noArrow: true,
    small: true,
    text: 'about.banner.buttons.contacts'
  }
];

const BENEFITS = [
  {
    title: 'about.benefits.b1.title',
    description: 'about.benefits.b1.description',
    icon: ObjectiveIcon
  },
  {
    title: 'about.benefits.b2.title',
    description: 'about.benefits.b2.description',
    icon: EyeIcon
  },
  {
    title: 'about.benefits.b3.title',
    description: 'about.benefits.b3.description',
    icon: InfrastructureIcon
  }
];

// Hidden for now:
// <AboutMedia />
// <AboutMediaKit />

const AboutPage = ({ pageContext: { locale } }) => (
  <Layout locale={locale}>
    <SEO title="about.title" />
    <AboutHeader />
    <Benefits
      alignTextLeft
      benefits={BENEFITS}
      description="about.benefits.description"
      title="about.benefits.title"
    />
    <AboutMore />
    <Awards/>
    <Banner
      buttons={bannerButtons}
      image={bannerImage}
      title="about.banner.title"
      text="about.banner.text"
    />
  </Layout>
);

export default AboutPage;
