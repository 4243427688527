import React from 'react';

import Featured from 'components/featured';

import './styles.scss';

const featuredImage1 = 'about/clients.jpeg';
const featuredImage2 = 'about/couriers.jpeg';
//const featuredImage3 = 'about/transporters.jpeg';
const featuredImage3 = 'about/transporter_route_planner.jpg';
const featuredImage4 = 'about/partners.jpeg';

const AboutFeatured = () => (
  <div className="about-featured">
    <div className="container-md about-featured__container">
      <Featured
        classNames="about-featured__featured-row"
        description="about.featured.f1.description"
        image={featuredImage1}
        linkProps={{
          text: 'about.featured.f1.linktext',
          to: '/how-it-works'
        }}
        title="about.featured.f1.title"
      />
      <Featured
        classNames="about-featured__featured-row"
        description="about.featured.f2.description"
        image={featuredImage2}
        invert
        linkProps={{
          text: 'about.featured.f2.linktext',
          to: '/transporter'
        }}
        title="about.featured.f2.title"
      />
      <Featured
        classNames="about-featured__featured-row"
        description="about.featured.f3.description"
        image={featuredImage3}
        linkProps={{
          text: 'about.featured.f3.linktext',
          to: '/contacts'
        }}
        title="about.featured.f3.title"
      />
      <Featured
        classNames="about-featured__featured-row"
        description="about.featured.f4.description"
        image={featuredImage4}
        invert
        linkProps={{
          text: 'about.featured.f4.linktext',
          to: '/partners'
        }}
        title="about.featured.f4.title"
      />
    </div>
  </div>
);

export default AboutFeatured;
