import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import Link from 'utils/localized-link'
import Button from '@youship/components/objects/button';

import Separator from 'components/separator';

import './styles.scss';


const AboutMore = () => {
  const intl = useIntl();
  const description = intl.formatMessage({ id: 'about.more.rd.description' });
  const description2 = intl.formatMessage({ id: 'about.more.history.description' });
  return (
  <>
  <div className="about-more">
    <div className="container">
      <div className="row">
        <div className="col col-12 col-lg-5 col-xl-4">
          <h2 className="h1--display about-more__title">
            <FormattedMessage id="about.more.rd.title" />
          </h2>
          <div className="about-more__description">
          </div>
        </div>
        <div className="col col-12 col-lg-7 offset-xl-1">
              <div className="about-more__award-text">
                <div dangerouslySetInnerHTML={{ __html: description }} />
              </div>
        </div>
      </div>
    </div>
  </div>

  <div className="about-more about-more__next">
    <div className="container">
      <div className="row">
        <div className="col col-12 col-lg-5 col-xl-4">
          <h2 className="h1--display about-more__title">
            <FormattedMessage id="about.more.history.title" />
          </h2>
          <div className="about-more__description">
          </div>
        </div>
        <div className="col col-12 col-lg-7 offset-xl-1">
            <div className="about-more__award-text">
              <div dangerouslySetInnerHTML={{ __html: description2 }} />
            </div>
            <div className="about-more__award-desc">
              <Button
                context="primary"
                noBackground
                noPadding
                noShadow
                small
                linkComponent={Link}
                linkProps={{ to: '/expertise' }}
              >
                <FormattedMessage id="about.more.expertise" />
              </Button>
            </div>
        </div>
      </div>
    </div>
  </div>
  </>
)
};

export default AboutMore;
