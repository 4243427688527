import React from 'react';
//import { Link } from 'gatsby';
import Link from 'utils/localized-link'
import { FormattedMessage } from 'react-intl';

import Button from '@youship/components/objects/button';
import Img from 'components/image';

import './styles.scss';

const headerImage = 'about/4.jpg';

const AboutHeader = () => (
  <div className="about-header">
    <div className="container">
      <div className="row justify-content-center">
        <div className="col col-12 col-md-7">
          <div className="about-header__title-container">
            <div className="about-header__title-wrapper">
              <h1 className="h1--display about-header__title">
                <FormattedMessage id="about.header.title" />
              </h1>
              <div className="about-header__title-filler" />
            </div>
          </div>
         {/*  <div className="about-header__description">
            <FormattedMessage id="about.header.subtitle" />
          </div> */}
         {/*  <Button
            context="primary"
            noBackground
            noPadding
            noShadow
            small
            linkComponent={Link}
            linkProps={{ to: '/contacts' }}
          >
             <FormattedMessage id="about.header.contact" />
          </Button> */}
        </div>
      </div>
      <div className="about-header__image-wrapper">
        <div className="about-header__background-element" />
        <Img
          src={headerImage}
          alt="Header 1"
          classNames="about-header__image"
        />
      </div>
    </div>
  </div>
);

export default AboutHeader;
